<template>
  <div class="card card--senior-call-header">
    <div
      v-b-toggle="`collapseInstitution`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Select Institution</a>
      </h3>
      <div class="heading-elements">
        <ul

          class="list-inline mb-0"
        >
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseInstitution`"
      visible
    >
      <div class="card-content">
        <div class="card-body">
          <div class="row d-flex align-items-center justify-content-between">
            <div class="col">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Search institution</label>
                <v-select
                  v-if="institutions.length !== undefined"
                  label="code"
                  :options="institutions"
                  :get-option-key="option => option.id"
                  @input="changeCaeSections($event)"
                  @search="onSearch({ code: $event }, 'institutions/filter')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCollapse,
} from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      institutions: 'institutions/institutions',
      fields: 'modals/tableSettingsFields',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    this.$store.dispatch('institutions/filter', this.filters)
  },
  methods: {
    async onSearch(search, store) {
      if (search.code !== '' && search.code.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    async changeCaeSections(institution) {
      if (institution) {
        this.$store.dispatch('filters/saveFilters', {
          value: institution,
          field: 'host',
        })

        this.$store.dispatch('cae/saveInstitution', institution)
        this.$store.dispatch('cae/toggleLoading', true)
        await this.$store.dispatch('cae/fetch', this.filters)
        await this.$store.dispatch('cae/fetchIcreas', this.filters)
        this.$store.dispatch('cae/toggleLoading', false)
      } else {
        await this.$store.dispatch('cae/clear')
        this.$store.dispatch('cae/saveInstitution', null)
      }
    },
  },
}
</script>
