<template>
  <div class="card">
    <div class="card-body text-center d-flex align-items-center flex-column">
      <div class="card--cae--content mb-auto">
        <!-- <div class="mb-1">
          <img
            src="../../../../app-assets/images/escudo-seguro.png"
            alt="name category"
            class="round"
            height="40"
            width="40"
          >
        </div> -->
        <h3>{{ section.name }}</h3>
        <p class="text-muted">
          <template v-if="section.subsections">
            {{ section.subsections }} subsections /
          </template>
          {{ section.documents }} documents
        </p>
      </div>
      <a
        class="btn btn-small btn-primary"
        @click="$router.push({name:'cae.view', params: {id: section.id}, query: {host: (institution ? institution.id : null), actAs: $route.query.actAs}})"
      >View documents</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    section: { type: Object, required: true },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      institution: 'cae/institution',
    }),
  },
  mounted() {

  },
}
</script>
