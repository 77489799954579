<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ loggedUser.roles.includes('researcher') ? 'Occupational Safety and Health (OSH)' : 'CAE' }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <span
              class="dis-ib ms-50"
              title="Search documents"
              @click="openFilters"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span>
            <a
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')"
              class="btn btn-icon btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Upload file"
              @click="$router.push({name: 'cae.labour-hazards.create'})"
            ><i data-feather="upload" /></a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div
            v-if="loggedUser.roles.includes('super-admin')"
            class="col-12"
          >
            <SelectInstitution />
          </div>

          <div class="col-12">
            <b-tabs
              v-if="!loading"
              lazy
            >
              <b-tab
                active
              >
                <template #title>
                  Section & Subsections
                </template>

                <div class="row">
                  <div class="col-sm-6">
                    <v-select
                      :placeholder="'Search section or subsection'"
                      label="name"
                      :options="sections"
                      :get-option-key="option => option.id"
                      @input="selectedSection"
                    />
                  </div>
                </div>
                <hr>
                <div
                  class="row"
                >
                  <div
                    v-for="section in filteredSections"
                    :key="section.id"
                    class="col-4"
                  >
                    <CaeSection :section="section" />
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')">
                <template #title>
                  ICREA'S
                </template>
                <div class="row">
                  <div class="col-sm-6">
                    <v-select
                      :placeholder="'Search icreas'"
                      label="name"
                      :options="icreas"
                      :get-option-key="option => option.id"
                      @input="selectedIcreaR"
                    />
                  </div>
                </div>
                <hr>
                <div
                  class="row"
                >
                  <div
                    v-for="icrea in filteredIcreas"
                    :key="icrea.id"
                    class="col-sm-4 row-cae-card--container"
                  >
                    <CaeIcrea :icrea="icrea" />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <div v-if="loading">
              <div class="loading custom-loading">
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Search />
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import SelectInstitution from './partials/SelectInstitution.vue'
import CaeSection from './partials/CaeSection.vue'
import CaeIcrea from './partials/CaeIcrea.vue'
import Search from './partials/Search.vue'

export default {
  components: {
    BTab,
    BTabs,
    SelectInstitution,
    vSelect,
    CaeSection,
    CaeIcrea,
    Search,
  },
  data() {
    return {
      selected: null,
      selectedIcrea: null,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      sections: 'cae/items',
      icreas: 'cae/icreas',
      loading: 'cae/loading',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
    }),
    filteredSections() {
      return this.sections.filter(e => !this.selected || e.id === this.selected.id)
    },
    filteredIcreas() {
      return this.icreas.filter(e => !this.selectedIcrea || e.id === this.selectedIcrea.id)
    },
  },
  async mounted() {
    if (this.loggedUser.roles.includes('cae')) {
      this.$store.dispatch('cae/toggleLoading', true)
      this.$store.dispatch('cae/saveInstitution', this.loggedUser.hosts[0])
      await this.$store.dispatch('cae/fetch', this.filters)
      await this.$store.dispatch('cae/fetchIcreas', this.filters)
    } else if (this.loggedUser.roles.includes('researcher')) {
      await this.$store.dispatch('cae/fetch', this.filters)
    }

    this.$store.dispatch('cae/toggleLoading', false)
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    selectedSection(item) {
      this.selected = item
    },
    selectedIcreaR(item) {
      this.selectedIcrea = item
    },
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
  },
}
</script>
